import Vue from "vue";
import Vuex from "vuex";
import shopOrder from "./modules/shopOrder";
import iseyaOrder from "./modules/iseyaOrder";
import shopLogin from "./modules/shopLogin";
import VueCookies from "vue-cookies";
import axios from "axios";
import ApiClient from "@/module/api";
Vue.use(Vuex);
Vue.use(VueCookies);

export default new Vuex.Store({
  state: {
    token: null,
    products: [],
    transfers: null,
    prefectures: null,
    shops: [],
    owners: [],
    fixedMenu: true, //#fixedMenuの表示状態
    todaysLock: false, //本日締切分の受付状況
    deadLineLabel: true //締切ラベルの表示状態
  },
  modules: {
    shopOrder,
    shopLogin,
    iseyaOrder
  },
  mutations: {
    token(state, token) {
      state.token = token;
      VueCookies.set("__jwt_token", token);
    },
    setProducts: function(state, products) {
      state.products = products;
    },
    setTransfers: function(state, transfers) {
      state.transfers = transfers;
    },
    setShops: function(state, shops) {
      state.shops = shops;
    },
    setOwners: function(state, owners) {
      state.owners = owners;
    }
  },
  actions: {
    token(state, token) {
      state.commit("token", token);
    },
    getProducts: async function ({ commit }) {
      const api = new ApiClient();
      const result = await api.get("/store/item/list");
      commit("setProducts", result.list);
    },
    // getTransfers: function({ commit }) {
    //   return axios.get("/_testData/transfer.json").then((response) => {
    //     commit("setTransfers", response.data);
    //   });
    // },
    getShops: function({ commit }) {
      return axios.get("/_testData/shop.json").then(response => {
        commit("setShops", response.data);
      });
    },
    getOwners: function({ commit }) {
      return axios.get("/_testData/owner.json").then(response => {
        commit("setOwners", response.data);
      });
    }
  },
  getters: {
    token(state) {
      if (!state.token) {
        if (!VueCookies.get("__jwt_token")) {
          return "";
        }
        state.token = VueCookies.get("__jwt_token");
      }
      return state.token;
    },
    async transfers(state) {
      if (!state.transfers) {
        const api = new ApiClient();
        const result = await api.get("/iseya/transfer/list");
        state.transfers = result.list;
      }
      return state.transfers;
    },
    async prefectures(state) {
      if (!state.prefectures) {
        const api = new ApiClient();
        const result = await api.get("/pref");
        state.prefectures = result.list;
      }
      return state.prefectures;
    }
  }
});
