import ApiClient from "@/module/api";

export default {
  state: {
    id: null,
    name: null,
    is_owner: 0,
    shop_id: 0,
    shop_name: ""
  },
  getters: {
    async getStoreLoginData(state) {
      if (!state.id) {
        const api = new ApiClient();
        const result = await api.get("/store/state");
        state.id = result.id;
        state.name = result.name;
        state.is_owner = result.is_owner;
        state.shop_id = result.shop_id;
        state.shop_name = result.shop_name;
      }
      return state;
    }
  },
  mutations: {
    setLoginInfo(state, param) {
      state.id = param.id;
      state.name = param.name;
      state.is_owner = param.is_owner;
      state.shop_id = param.shop_id;
      state.shop_name = param.shop_name;
    },
    setSelectShop(state, param) {
      state.shop_id = param.id;
      state.shop_name = param.name;
    }
  },
  actions: {
    setLoginInfo(state, param) {
      state.commit("setLoginInfo", param);
    },
    setSelectShop(state, param) {
      state.commit("setSelectShop", param);
    }
  }
};
