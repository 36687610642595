export default {
  state: {
    data: {
      fetchComplete: true,
      order: []
    }
  },
  getters: {},
  mutations: {
    iseyaMinus(state, { products, id, orderNum }) {
      //当該商品の個数
      state.data.order[orderNum].product[id].quantity--;

      //0個以下にならないようにする、0個になったら薄くする
      if (state.data.order[orderNum].product[id].quantity <= 0) {
        state.data.order[orderNum].product[id].quantity = 0;
      }

      //当日の合計 TODO 多分この辺の計算は個別に分割して、actionsでまとめて呼び出すのが良い
      let thisDayQt = 0;
      let thisDayWeight = 0;
      let thisDaySubTotal = 0;
      for (let $i = 0; $i < state.data.order[orderNum].product.length; $i++) {
        thisDayQt = thisDayQt + state.data.order[orderNum].product[$i].quantity;

        thisDayWeight =
          thisDayWeight +
          state.data.order[orderNum].product[$i].quantity *
            products[$i].orderUnitWeight;

        thisDaySubTotal =
          thisDaySubTotal +
          state.data.order[orderNum].product[$i].quantity * products[$i].price;
      }
      state.data.order[orderNum].thisDayQt = thisDayQt;
      state.data.order[orderNum].thisDaySubTotal = thisDaySubTotal;
      if (thisDayQt < 0) {
        thisDayQt = 0;
      }
    },
    iseyaPlus(state, { targetProduct, index }) {
      //当該商品の個数
      console.log(state.data.order[index].products);
      console.log(targetProduct);
      // return;
      // state.data.order[index].product[index].quantity++;

      // //当日の合計 TODO 多分この辺の計算は個別に分割して、actionsでまとめて呼び出すのが良い
      // let thisDayQt = 0;
      // let thisDayWeight = 0;
      // let thisDaySubTotal = 0;
      // for (let $i = 0; $i < state.data.order[orderNum].product.length; $i++) {
      //   //合計の数
      //   thisDayQt = thisDayQt + state.data.order[orderNum].product[$i].quantity;

      //   thisDayWeight =
      //     thisDayWeight +
      //     state.data.order[orderNum].product[$i].quantity *
      //       products[$i].orderUnitWeight;

      //   thisDaySubTotal =
      //     thisDaySubTotal +
      //     state.data.order[orderNum].product[$i].quantity * products[$i].price;
      // }
      // state.data.order[orderNum].thisDayQt = thisDayQt;
      // state.data.order[orderNum].thisDayWeight = thisDayWeight;
      // state.data.order[orderNum].thisDayBoxQt = Math.ceil(thisDayWeight / 3000);
      // state.data.order[orderNum].thisDaySubTotal = thisDaySubTotal;
    },

    iseyaVacuumMinus(state, { id, orderNum }) {
      //当該商品の個数
      state.data.order[orderNum].product[id].vacuumQuantity--;
      //0個以下にならないようにする
      if (state.data.order[orderNum].product[id].vacuumQuantity <= 0) {
        state.data.order[orderNum].product[id].vacuumQuantity = 0;
      }
    },
    iseyaVacuumPlus(state, { id, orderNum }) {
      //当該商品の個数

      let quantity = state.data.order[orderNum].product[id].quantity;
      let vacuumQuantity =
        state.data.order[orderNum].product[id].vacuumQuantity;
      if (quantity > vacuumQuantity) {
        state.data.order[orderNum].product[id].vacuumQuantity++;
      }
    }
  },
  actions: {
    //plus(context, number) {
    //  context.commit("plus", number);
    //},
  }
};
