<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png" />
    <div>疎通確認:<span>{{result}}</span></div>
  </div>
</template>

<script>
// @ is an alias to /src

import ApiClient from "@/module/api";

export default {
  data: function() {
    return {
      result: "NG"
    };
  },
  mounted: async function() {
    const api = new ApiClient();
    const result = await api.get('test');
    this.result = result.data;

    // productサンプル
    // const param = {
    //   fetchComplete: false,
    //   order: [
    //     {
    //       active: true,
    //       id: 0,
    //       date: "date",
    //       thisDayQt: 0,
    //       thisDayWeight: 0,
    //       thisDayBoxQt: 0,
    //       thisDaySubTotal: 0,
    //       product: [
    //         {
    //           id: 0,
    //           quantity: 0,
    //         },
    //         {
    //           id: 1,
    //           quantity: 0,
    //         },
    //         {
    //           id: 2,
    //           quantity: 0,
    //         },
    //         {
    //           id: 3,
    //           quantity: 0,
    //         },
    //         {
    //           id: 4,
    //           quantity: 0,
    //         },
    //         {
    //           id: 5,
    //           quantity: 0,
    //         },
    //         {
    //           id: 6,
    //           quantity: 0,
    //         },
    //         {
    //           id: 7,
    //           quantity: 0,
    //         },
    //       ],
    //     }
    //   ]
    // };
    // await api.post('/product', param)
  }
};
</script>
