import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/shop/login",
    name: "shop login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/shop/login.vue"),
    props: true
  },
  {
    path: "/shop/shop-select",
    name: "shop select",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/shop/shopSelect.vue"),
    props: true
  },
  {
    path: "/shop/address/",
    name: "address",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/shop/address.vue"),
    props: true
  },
  {
    path: "/shop/home",
    name: "shop home",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/shop/home.vue")
  },
  {
    path: "/shop/order",
    name: "shopOrder",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/shop/order.vue"),
    props: true
  },
  {
    path: "/shop/order/confirm/",
    name: "shopOrderConfirm",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/shop/orderConfirm.vue"),
    props: true
  },
  {
    path: "/shop/order/history/",
    name: "shopOrderHistory",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/shop/orderHistory.vue"),
    props: true
  },
  {
    path: "/shop/shipment/",
    name: "shopShipment",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/shop/shipment.vue"),
    props: true
  },
  {
    path: "/shop/payments/",
    name: "shopPayment",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/shop/payments.vue"),
    props: true
  },
  {
    path: "/shop/order/extra/",
    name: "shopExtraOrder",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/shop/extraOrder.vue"),
    props: true
  },
  {
    path: "/shop/order/extra/confirm/",
    name: "shopExtraOrderConfirm",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/shop/extraOrderConfirm.vue"
      ),
    props: true
  },
  {
    path: "/shop/order/event/",
    name: "shopEventOrder",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/shop/eventOrder.vue"),
    props: true
  },
  {
    path: "/shop/order/event/confirm/",
    name: "shopEventOrderConfirm",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/shop/eventOrderConfirm.vue"
      ),
    props: true
  },
  /*

  イセヤ画面

  ----------------------------------------------------------------------------------------*/
  {
    path: "/iseya/login",
    name: "iseya login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/iseya/login.vue"),
    props: true
  },
  {
    path: "/iseya/home",
    name: "iseya home",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/iseya/home.vue"),
    props: true
  },
  {
    path: "/iseya/order/",
    name: "iseya order",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/iseya/order/index.vue"),
    props: true
  },
  {
    path: "/iseya/order/detail",
    name: "iseya orderDetail/",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/iseya/order/detail.vue"),
    props: true
  },
  {
    path: "/iseya/order/detail/edit/",
    name: "iseya orderDetail",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/iseya/order/detailEdit.vue"
      ),
    props: true
  },
  {
    path: "/iseya/order/weekly/",
    name: "iseya orderWeekly",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/iseya/order/weekly.vue"),
    props: true
  },
  {
    path: "/iseya/order/weekly/detail/",
    name: "iseya orderWeeklyDetail",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/iseya/order/weeklyDetail.vue"
      ),
    props: true
  },
  {
    path: "/iseya/master/owner/",
    name: "iseya masterOwner",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/iseya/master/owner.vue"),
    props: true
  },
  {
    path: "/iseya/master/shop/",
    name: "iseya masterShop",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/iseya/master/shop.vue"),
    props: true
  },
  {
    path: "/iseya/master/product/",
    name: "iseya masterProduct",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/iseya/master/product.vue"
      ),
    props: true
  },
  {
    path: "/iseya/master/transfer/",
    name: "iseya masterTransfer",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/iseya/master/transfer.vue"
      ),
    props: true
  },
  {
    path: "/iseya/news/",
    name: "iseya news",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/iseya/news/index.vue"),
    props: true
  },
  {
    path: "/iseya/sales/daily/",
    name: "iseya salesDaily",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/iseya/sales/daily.vue"),
    props: true
  },
  {
    path: "/iseya/sales/monthly/",
    name: "iseya salesMonthly",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/iseya/sales/monthly.vue"
      ),
    props: true
  },
  {
    path: "/iseya/sales/shop/daily/",
    name: "iseya salesShopDaiy",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/iseya/sales/shop/daily.vue"
      ),
    props: true
  },
  {
    path: "/iseya/sales/shop/monthly/",
    name: "iseya salesShopMonthly",
    component: () => import("../views/iseya/sales/shop/monthly.vue"),
    props: true
  },
  {
    path: "/iseya/invoice/archive/",
    name: "iseya salesShopMonthly",
    component: () => import("../views/iseya/invoice/archive.vue"),
    props: true
  },
  {
    path: "/iseya/invoice/detail/",
    name: "iseya salesShopMonthly",
    component: () => import("../views/iseya/invoice/detail.vue"),
    props: true
  },
  {
    path: "/iseya/invoice/recent/",
    name: "iseya salesShopMonthly",
    component: () => import("../views/iseya/invoice/recent.vue"),
    props: true
  },
  {
    path: "/iseya/invoice/recent/detail/",
    name: "iseya salesShopMonthly",
    component: () => import("../views/iseya/invoice/recentDetail.vue"),
    props: true
  },
  {
    path: "/iseya/shipping/deliver/",
    name: "iseya salesShopMonthly",
    component: () => import("../views/iseya/shipping/deliver.vue"),
    props: true
  },
  {
    path: "/iseya/shipping/deliver/shop/",
    name: "iseya salesShopMonthly",
    component: () => import("../views/iseya/shipping/deliverShop.vue"),
    props: true
  },
  {
    path: "/iseya/shipping/deliver/detail/",
    name: "iseya salesShopMonthly",
    component: () => import("../views/iseya/shipping/deliverDetail.vue"),
    props: true
  },
  {
    path: "/iseya/shipping/csv/",
    name: "iseya salesShopMonthly",
    component: () => import("../views/iseya/shipping/csv.vue"),
    props: true
  },
  {
    path: "/iseya/shipping/instructions/",
    name: "iseya salesShopMonthly",
    component: () => import("../views/iseya/shipping/instructions.vue"),
    props: true
  },
  {
    path: "/iseya/shipping/instructions/detail/all/",
    name: "iseya salesShopMonthly",
    component: () =>
      import("../views/iseya/shipping/instructionsDetailAll.vue"),
    props: true
  },
  {
    path: "/iseya/shipping/instructions/detail/shop/",
    name: "iseya salesShopMonthly",
    component: () =>
      import("../views/iseya/shipping/instructionsDetailShop.vue"),
    props: true
  },
  {
    path: "/iseya/order/extraOrder/",
    name: "iseya salesShopMonthly",
    component: () => import("../views/iseya/order/extraOrder.vue"),
    props: true
  },
  {
    path: "/iseya/order/extraDetail/",
    name: "iseya salesShopMonthly",
    component: () => import("../views/iseya/order/extraOrderDetail.vue"),
    props: true
  },
  {
    path: "/iseya/order/extraDetail/edit/",
    name: "iseya salesShopMonthly",
    component: () => import("../views/iseya/order/extraOrderDetailEdit.vue"),
    props: true
  },
  {
    path: "/iseya/order/extraWeekly/",
    name: "iseya salesShopMonthly",
    component: () => import("../views/iseya/order/extraWeekly.vue"),
    props: true
  },
  {
    path: "/iseya/order/extraWeeklyDetail/",
    name: "iseya salesShopMonthly",
    component: () => import("../views/iseya/order/extraWeeklyDetail.vue"),
    props: true
  }
  // {
  //   path: "/iseya/calc/",
  //   name: "iseya salesShopMonthly",
  //   component: () => import("../views/iseya/calc.vue"),
  //   props: true,
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
